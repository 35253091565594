(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/kambi-offering/assets/javascripts/hooks/use-kambi-bet-offers.js') >= 0) return;  svs.modules.push('/components/oddset/kambi-offering/assets/javascripts/hooks/use-kambi-bet-offers.js');
"use strict";

const {
  useState,
  useReducer,
  useCallback,
  useEffect
} = React;
const {
  common: {
    normalize: {
      normalizeBetOffers,
      normalizeSocketMessages
    }
  },
  store: {
    reducer,
    initState,
    ActionType
  },
  hooks: {
    useBetOfferSockets
  },
  getBetOffers
} = svs.components.oddset.kambiOffering;
const useInterval = svs.components.lbUtils.useInterval;
const logger = svs.core.log.getLogger('components:oddset:kambi-offerings:hooks:useKambiBetOffers');
const useKambiBetOffers = _ref => {
  let {
    kambiEventId,
    betOfferTypesArr,
    englishLabelsArr,
    shouldConnectSockets = true,
    pollingDelay = 15 * 1000
  } = _ref;
  const [state, dispatch] = useReducer(reducer, initState);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [resetPolling, setResetPolling] = useState(0);
  const intervalId = useInterval(() => {
    const fetchBetOffers = async () => {
      if (!kambiEventId || !(betOfferTypesArr !== null && betOfferTypesArr !== void 0 && betOfferTypesArr.length) || !(englishLabelsArr !== null && englishLabelsArr !== void 0 && englishLabelsArr.length)) {
        logger.error('Missing parameters in useKambiBetOffers hook.');
        return;
      }
      setIsLoading(true);
      const response = await getBetOffers(kambiEventId, betOfferTypesArr);
      const {
        betOffers: betOffersRes,
        error
      } = response;
      if (error) {
        logger.error("Error fetching betOffers for event ".concat(kambiEventId), error);
        setError(error);
        setIsLoading(false);
        return;
      }
      const normalizedBetOffers = englishLabelsArr.reduce((acc, englishLabel) => {
        const filteredOffers = betOffersRes.filter(betOffer => betOffer.criterion.englishLabel === englishLabel);
        const normalized = normalizeBetOffers(filteredOffers, response);
        normalized.forEach(betOffer => {
          acc[betOffer.id] = betOffer;
        });
        return acc;
      }, {});
      dispatch({
        type: ActionType.SetBetOffers,
        payload: normalizedBetOffers
      });
      setIsLoading(false);
    };
    fetchBetOffers();
  }, pollingDelay, true, resetPolling);
  useEffect(() => {
    clearTimeout(intervalId);
    setResetPolling(value => value + 1);
  }, [kambiEventId]);
  const onSocketMessageCallback = useCallback(messageArr => {
    clearTimeout(intervalId);
    const normalizedMessages = normalizeSocketMessages(messageArr);
    normalizedMessages.forEach(message => {
      dispatch({
        type: message.type,
        payload: message.payload
      });
    });
    setResetPolling(value => value + 1);
  }, [intervalId]);
  useBetOfferSockets(kambiEventId, onSocketMessageCallback, shouldConnectSockets);
  return {
    betOffers: state,
    error,
    isLoading
  };
};
setGlobal('svs.components.oddset.kambiOffering.hooks.useKambiBetOffers', useKambiBetOffers);

 })(window);